import request from '@/libs/request'

/**
 * 商城首页数据接口
 ***/
export function Initial() {
  return request({
    url: '/initial',
    method: 'get',
  })
}

/**
 * 商城系统商品分类接口
 ***/
export function GoodsCate() {
  return request({
    url: '/goodscate',
    method: 'get',
  })
}


/**
 * 系统商品分类商品列表接口
 ***/
export function GoodsList(data) {
  return request({
    url: '/goodslist',
    method: 'post',
    data
  })
}

/**
 * 门店首页数据接口
 ***/
export function StoreInitial(data) {
  return request({
    url: '/store/initial',
    method: 'post',
    data
  })
}

/**
 * 门店头部数据 商品详情页 门店商品分类页面  门店首页共用此接口
 ***/
export function StoreHead(data) {
  return request({
    url: '/store/storehead',
    method: 'post',
    data
  })
}

/**
 * 门店头部数据 商品详情页 门店商品分类页面  门店首页共用此接口
 ***/
export function StoreGoodsList(data) {
  return request({
    url: '/store/goodslist',
    method: 'post',
    data
  })
}